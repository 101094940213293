export const SystemMsgs = {
  Name: () => {
    return 'Name is required'
  },
  Remark: () => {
    return 'Remark is required'
  },
  requestedBy: () => {
    return 'Requested by is required'
  },
  contactPerson: () => {
    return 'Contact Person is required'
  },
  mobileNo: () => {
    return 'Mobile No is required'
  },
  specialInstruction: () => {
    return 'Special instruction is required'
  },
  createNewRecord: () => {
    return 'Record added successfully.'
  },
  updateRecord: () => {
    return 'Record updated  successfully.'
  },
}
